/*
*   Script to parse xml-files exported by Windchill search result
*
* */
const parseString = require('xml2js').parseString;
const {promisify} = require('util');

/**
 * returns the columns from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the column names as an array
 * */
export const getColumnNames = async(xml) => {
    const object = await convertXMLToObject(xml);
    const items = Object.keys(object);
    if(items.length > 1) {
        throw new Error('XML has multiple roots')
    }
    const records = object[items[0]].record;
    return Object.keys(records[0]);
};

/**
 * returns the data from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the xml data rows as an array
 * */
 export const getData = async(xmlString) => {
    const columns = await this.getColumnNames(xmlString);

    const object = await convertXMLToObject(xmlString);
    const items = Object.keys(object);
    if(items.length > 1) {
        throw new Error('XML has multiple roots')
    }
    const rows = object[items[0]].record;

    const result = [];
    for(let i = 0; i < rows.length; i++) {
        let object = {};
        for(let j = 0; j < columns.length; j++) {
            let columnName = columns[j];
            const str = rows[i][columnName][0];
            object[columnName] = str ? str.trim() : '';
        }
        result.push(object);
    }
    return result;
};


/**
 * Converts an XML-String to an object
 * @param xml {String}
 * @returns {Object} an object with the XML-contents
 * */
const convertXMLToObject = async (xml) => {
    const parse = promisify(parseString);
    return await parse(xml).then(result => {
        if(result) {
            return result;
        }
    });
};